import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAlert } from '../../Redux/actions/alert';
import { login } from '../../Redux/actions/auth';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

const styles = {
    rowStyle: {
        height: '80vh',
    },
    bgImg: {
        height: '40vw',
        opacity: 0.05,
        position: 'absolute',
        left: '-220px',
    },
    btnPagina: {
        fontSize: '1rem',
        letterSpacing: 'inherit'
    },
    btnIniciar: {
        background: '#9B3131',
        borderColor: '#9B3131',
        color: '#FFFFFF',
        fontSize: '1rem',
        letterSpacing: 'inherit'
    },
    inputForm: {
        fontSize: '1rem'
    }    
}

const Login = ({ setAlert, login, isAuthenticated }) => {

    const [loginData, setLoginData ] = useState({
        usuario: '',
        password: ''
    });

    // Redireccionamos si está autenticado
    if (isAuthenticated) {
        return <Redirect to="/dashboard"></Redirect>
    }

    const { usuario, password } = loginData;

    const onChange = (e) => {
        setLoginData({
            ...loginData, [e.target.name]: e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        login({ usuario, password});
    }

    
    return (
        <div className="row d-flex align-items-center no-margin-row" style={styles.rowStyle}>
            <Helmet>
                <style>{'body { background-color: #F69D9D; }'}</style>
            </Helmet>
            <img src="logo.png" className="d-inline-block my-4" alt="logo" style={styles.bgImg}/>
            <div className="col-md-6 offset-md-3">
                <div className="card bg-light ">
                    <div className="card-body">
                        <div className="text-center mb-2">
                            <img src="/assets/images/logo-alt.png" className="d-inline-block my-3" alt="logo" height="65" />
                        </div>
                        <form onSubmit={submitForm} method="POST">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nombre de usuario"
                                    name="usuario"
                                    value={usuario}
                                    style={styles.inputForm}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Contraseña"
                                    name="password"
                                    value={password}
                                    style={styles.inputForm}
                                    onChange={e => onChange(e)}></input>
                            </div>
                            <div className="form-group text-right pt-3">
                                <a className="btn btn-secondary mr-2" href="/" style={styles.btnPagina}>Ir a la página</a>
                                <button type="submit" className="btn btn-primary" style={styles.btnIniciar}>Iniciar sesión</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

Login.propTypes = {
    setAlert: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, {
    setAlert, login
})(Login);