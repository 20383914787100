import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { crearInvitacion } from '../../Redux/actions/invitaciones';

const styles = {
    titulo_seccion: {
        fontFamily: 'inherit',
        fontSize: '24px',
        fontWeight: '500'
    },
    btn_tabla: {
        fontFamily: 'inherit',
        letterSpacing: 'inherit',
        fontSize: '16px'
    }
}

const InvitacionesNuevo = () =>{

    const dispatch = useDispatch();

    const history = useHistory();

    const [invitacionData, setInvitacionData] = useState({
        id_compra: process.env.REACT_APP_ID_COMPRA,
        referencia: '',
        numero: '',
        codigo: '',
        adultos: [],
        ninos: []
    });

    const makeId = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHJKLMNOPQRSTUVWXYZ';
        var charactersLength = characters.length;
        
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        
        return result;
    }

    const padWithZeroes = (number, length) => {
        var my_string = '' + number;
        while (my_string.length < length) {
            my_string = '0' + my_string;
        }
        return my_string;
    }

    const getRndInteger = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    }

    const onChange = (e) => {
        setInvitacionData({
            ...invitacionData,
            [e.target.name]: e.target.value
        });
    }

    //Adultos
    const onChangeAdultos = (e) => {
        let adultos = invitacionData.adultos;

        if(adultos?.length > e.target.value){
            adultos.splice(e.target.value, (adultos?.length - e.target.value));
        }else{
            for (var x = 1; x <= (e.target.value - adultos?.length); x++) {
                adultos.push({
                    nombre: '',
                    status: false
                });
            }
        }

        setInvitacionData({
            ...invitacionData,
            adultos
        });
    }

    const onQuantityAdultos = (tipo) => {
        let adultos = invitacionData.adultos;

        if(tipo == 1){
            adultos.push({
                nombre: '',
                status: false
            });
        }else{
            adultos.splice(adultos.length - 1, 1);
        }

        setInvitacionData({
            ...invitacionData,
            adultos
        });
    }

    const onChangeInvitadoAdulto = (e) => {
        let adultos = invitacionData.adultos;

        let campo_separado = e.target.name.split('_');
        let posicion = campo_separado[2];

        adultos[posicion].nombre = e.target.value;

        setInvitacionData({
            ...invitacionData,
            adultos
        });
    }

    const onDeleteAdulto = async ({ currentTarget }) => {
        let adultos = invitacionData.adultos;

        let campo_separado = currentTarget.id.split('_');
        let posicion = campo_separado[2];

        console.log(adultos);
        
        adultos.splice(posicion, 1);
        
        console.log(adultos);

        setInvitacionData({
            ...invitacionData,
            adultos
        });
    }

    const adultosRows = (adultos) => {

        let filas = [];

        for (var x = 0; x < adultos?.length; x++) {
            filas.push(
                <div className="col-md-12 form-group" key={'boleto_adulto_' + x}>
                    <label className="font-weight-bold">Adulto {x + 1}</label>
                    <div class="input-group">
                        <input type="text" className="form-control" placeholder="Nombre del invitado" name={'boleto_adulto_' + x} value={adultos[x].nombre} onChange={e => onChangeInvitadoAdulto(e)}></input>
                        <div class="input-group-append">
                            <button class="btn btn-danger" type="button" id={'boleto_adulto_' + x} onClick={e => onDeleteAdulto(e)}><i className="icon-cancel-circle"></i></button>
                        </div>
                    </div>
                </div>
            );
        }

        return filas;
    }

    //Ninos
    const onChangeNinos = (e) => {
        let ninos = invitacionData.ninos;

        if(ninos?.length > e.target.value){
            ninos.splice(e.target.value, (ninos?.length - e.target.value));
        }else{
            for (var x = 1; x <= (e.target.value - ninos?.length); x++) {
                ninos.push({
                    nombre: '',
                    status: false
                });
            }
        }

        setInvitacionData({
            ...invitacionData,
            ninos
        });
    }

    const onQuantityNinos = (tipo) => {
        let ninos = invitacionData.ninos;

        if(tipo == 1){
            ninos.push({
                nombre: '',
                status: false
            });
        }else{
            ninos.splice(ninos.length - 1, 1);
        }

        setInvitacionData({
            ...invitacionData,
            ninos
        });
    }

    const onChangeInvitadoNino = (e) => {
        let ninos = invitacionData.ninos;

        let campo_separado = e.target.name.split('_');
        let posicion = campo_separado[2];

        ninos[posicion].nombre = e.target.value;

        setInvitacionData({
            ...invitacionData,
            ninos
        });
    }

    const onDeleteNino = async ({ currentTarget }) => {
        let ninos = invitacionData.ninos;

        let campo_separado = currentTarget?.id?.split('_');
        let posicion = campo_separado[2];

        await ninos.splice(posicion, 1);

        await setInvitacionData({
            ...invitacionData,
            ninos
        });
    }

    const ninosRows = (ninos) => {

        let filas = [];

        for (var x = 0; x < ninos?.length; x++) {
            filas.push(
                <div className="col-md-12 form-group">
                    <label className="font-weight-bold">Niño {x + 1}</label>
                    <div class="input-group">
                        <input type="text" className="form-control" placeholder="Nombre del invitado" name={'boleto_nino_' + x} value={ninos[x].nombre} onChange={e => onChangeInvitadoNino(e) }></input>
                        <div class="input-group-append">
                            <button class="btn btn-danger" type="button" id={'boleto_nino_' + x} onClick={e => onDeleteNino(e)}><i className="icon-cancel-circle"></i></button>
                        </div>
                    </div>
                </div>
            );
        }

        return filas;
    }
    
    //Guardar
    const handleCancel = async (e) => {
        history.push('/invitaciones_listado');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(crearInvitacion(invitacionData));
            await history.push('/invitaciones_listado');
        } catch (error) {
            return false;
        }
    }

    useEffect(() => {
        let codigo_generado = makeId(2) + padWithZeroes(getRndInteger(1, 999), 3);

        setInvitacionData({
            ...invitacionData,
            codigo: codigo_generado
        })
    }, []);

    return(
        <div className="row px-3">
            <form className="col-md-8 offset-md-2">

                <div className="row mb-4 mt-5">
                    <div className="col-md-8">
                        <h2 style={styles.titulo_seccion}>Nueva invitación</h2>
                    </div>
                    <div className="col-md-4 text-right">
                        <button type="submit" className="btn btn-danger mr-2" onClick={() => handleCancel()} style={styles.btn_tabla}>Cancelar</button>
                        <button type="submit" className="btn btn-success" onClick={handleSubmit} style={styles.btn_tabla}>Crear invitación</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Referencia</label>
                        <input type="text" className="form-control" placeholder="Nombre la familia o del invitado principal" name="referencia" onChange={e => onChange(e) } value={invitacionData.referencia}></input>
                    </div>

                    <div className="col-md-4 form-group">
                        <label className="font-weight-bold">Teléfono</label>
                        <PhoneInput name="numero" className="form-control" country={'mx'} onChange={phone => setInvitacionData({...invitacionData, numero: phone})} value={invitacionData.numero} />
                    </div>

                    <div className="col-md-2 form-group">
                        <label className="font-weight-bold">Código</label>
                        <input type="text" className="form-control" name="codigo" onChange={e => onChange(e) } value={invitacionData.codigo} disabled></input>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Boletos para adulto</label>
                        <div class="input-group">
                            <input type="number" min="0" className="form-control" placeholder="# de boletos" name="adultos" onChange={e => onChangeAdultos(e)} value={invitacionData.adultos?.length}></input>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={() => onQuantityAdultos(1)}><i className="icon-plus"></i></button>
                            </div>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={() => onQuantityAdultos(2)}><i className="icon-minus"></i></button>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="font-weight-bold">Boletos para niño</label>
                        <div class="input-group">
                            <input type="number" min="0" className="form-control" placeholder="# de boletos" name="ninos" onChange={e => onChangeNinos(e)} value={invitacionData.ninos?.length}></input>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={() => onQuantityNinos(1)}><i className="icon-plus"></i></button>
                            </div>
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="button" onClick={() => onQuantityNinos(2)}><i className="icon-minus"></i></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 row">
                        { adultosRows(invitacionData.adultos) }
                    </div>

                    <div className="col-md-6 row">
                        { ninosRows(invitacionData.ninos) }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default InvitacionesNuevo;